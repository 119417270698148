@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    src: url('./fonts/OpenSans-Light.ttf') format('ttf');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    src: url('./fonts/OpenSans-Bold.ttf') format('ttf');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    src: url('./fonts/OpenSans-Medium.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: url('./fonts/Montserrat-Regular.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('./fonts/Montserrat-Bold.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('./fonts/Montserrat-Medium.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: url('./fonts/Montserrat-Light.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    src: url('./fonts/Montserrat-Thin.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Gentium Book Basic';
    font-weight: 400;
    src: url('./fonts/gentium-book-basic.regular.ttf') format('ttf');
  }
  @font-face {
    font-family: 'Gentium Book Basic';
    font-weight: 700;
    src: url('./fonts/gentium-book-basic.bold.ttf') format('ttf');
  }
}

body {
  -webkit-font-smoothing: antialiased;
  @apply font-openSans;
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;
}

.form-label {
  @apply text-base !important;
}

strong {
  @apply font-medium;
}

.app-container {
}

.page-container-sm {
  @apply max-w-lg mx-auto px-4 sm:px-6 lg:px-8 py-10 md:py-20 translate-y-28;
}

.survey-container {
  @apply max-w-3xl mx-auto;
}

.section-container-sm {
  @apply max-w-lg mx-auto;
}

.page-container-md {
  @apply mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-2xl py-10 md:py-20 translate-y-28;
}

.page-container-lg {
  @apply mx-auto max-w-md sm:max-w-3xl px-2 sm:px-6 lg:px-8 lg:max-w-7xl;
}

/* Report Table Styles */
.text-vertical-td {
  vertical-align: middle !important;
}
.text-vertical-table {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: nowrap;
  @apply text-lg font-medium m-1 text-center;
}
.max-col-width {
  word-wrap: break-word;
  @apply w-20 text-center;
}

/* React Dates Styles */

.SingleDatePickerInput {
  border: none !important;
  outline: none !important;
}

.DateInput_input {
  @apply !ring-0 !block !border !outline-none focus:!border-black !text-[#333] !border-solid !border-[#BBBBBB] !rounded-none !py-2.5 !px-[5px] !text-base !placeholder-gray-600 !transition-all !my-2.5 !w-full;
}

.DateInput_input__focused {
  @apply !ring-0 !border !border-solid !border-black !outline-none !rounded-none !py-2.5 !px-[5px] !text-base !placeholder-gray-600 !transition-all !my-2.5;
}

/* NOTE: the order of these styles DO matter */
/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: #82e0aa;
  color: white;
  border: 1px solid red;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #d8d5f1 !important;
  color: #0a043d !important;
  border-color: #0a043d !important;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #d8d5f1 !important;
  color: white !important;
}

.CalendarMonth_caption {
  font-size: 16px;
}
.CalendarMonth_caption strong {
  font-weight: 500;
}

.prose {
  @apply text-brand-blue;
}

/* Form Fileinput style */
input[type='file']::-webkit-file-upload-button,
input[type='file']::file-selector-button {
  @apply text-white bg-brand-blue hover:bg-brand-blue-light hover:text-white transition-colors font-medium text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4 h-full;
  margin-inline-start: -1rem;
  margin-inline-end: 1rem;
}

input::placeholder {
  opacity: 2.5;
  color: white !important;
}

/* Footer Nav Items styling - remove first item border */
.nav-item-0 > a > div {
  padding-left: 0px !important;
}

.nav-item-0 > a > div:before {
  width: 0px !important;
}

.hide-element {
  position: absolute;
  left: -99999px;
}

.prose h2 {
  color: #0d1d42;
}

.header-wrapper {
  max-height: 120px;
}

.box-shadow-custom {
  box-shadow: 0px 12px 18px -6px #25408f;
}

.main-logo {
  width: 190px;
  height: 69px;
}

@media screen and (max-width: 768px) {
  .main-logo {
    width: 127px; /* Adjusted width for mobile */
    height: 46px; /* Adjusted height for mobile */
  }
  .header-wrapper {
    max-height: 109px;
  }
}

.footer-wrapper {
  background-image: url('./images/footerImage.png');
  background-repeat: no-repeat;
  background-position: center bottom 90%;
  background-size: cover;
}

.main-button {
  border-radius: 15px !important;
}
