html {
  /* text-align: center; */
  scroll-behavior: smooth;
}

html,
body,
#root,
.app-container,
.app-container > div,
.app-container > div > div {
  height: calc(100vh - 80px);
  background-color: #fff;
}

footer {
  position: sticky;
  top: 100vh;
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f9a31a;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 12px 16px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: 8px;
}

.floating-button:hover,
.floating-button:focus {
  background-color: #0056b3;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.floating-button:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}

.floating-button .icon {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.floating-button:hover .icon {
  background-color: rgba(255, 255, 255, 0.3);
}

.floating-button svg {
  width: 20px;
  height: 20px;
  fill: none;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.floating-button .label {
  font-weight: bold;
}

@media (max-width: 768px) {
  .floating-button {
    padding: 10px 16px 10px 10px;
    font-size: 14px;
  }

  .floating-button .icon {
    width: 32px;
    height: 32px;
  }

  .floating-button svg {
    width: 18px;
    height: 18px;
  }
}
